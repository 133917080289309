// Bootstrap overrides
$primary: #9DE5A6;

@import "bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

// App CSS definitions
h1, h2, h3, h4, h5, h6 {
    font-family: "Barlow", sans-serif;
}
.nav-container {
    margin-bottom: -126px;
}
.navbar {
    background-color: rgba(0, 0, 0, .7);
}
.feature {
    background-image: url(../images/feature.jpg);
    background-position: 50% 30%;
    background-repeat: no-repeat;
}
.feature-content {
    padding: 180px 0;
}
#estimateModal textarea {
    height: 100px;
}
.about {
    background-color: $white;
    color: $black;
    img {
        min-width: 300px;
        object-fit: cover;
        width: 100%; // 620px
    }
}
.service {
    background-color: var(--bs-tertiary-bg);
    margin-bottom: 1.8rem;
    min-width: 316px;
    padding-bottom: 1.8rem;
    h3 {
        background-color: $primary;
        color: $black;
        padding: 1rem;
        text-align: center;
        width: 100%;
    }
    p {
        padding: 0 1.8rem;
    }
    ul {
        padding: 0 1.8rem;
        list-style: none;
        width: 100%;
    }
    li {
        padding: 0.3rem;
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
    .price {
        font-size: 2.5rem;
        padding: .5rem 1.8rem;
        .currency {
            font-size: 1.5rem;
            vertical-align: top;
        }
    }
    .price-small {
        float: right;
        font-size: 1.5rem;
        margin-top: -0.3rem;
        padding-left: 1rem;
        .currency {
            font-size: 1rem;
            vertical-align: top;
        }
    }
}
.gallery {
    background-color: var(--bs-secondary-bg);
    .gallery-item {
        border: 0;
        margin: 0.5rem;
        padding: 0;
        img {
            border: 1px solid $secondary;
            height: 220px;
            object-fit: cover;
            width: 160px;
            &.fullsize {
                display: none;
            }
        }
    }
}
#galleryModal {
    img {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
}
footer {
    .col-2 {
        min-width: 181px;
    }
}

/* ANIMATIONS */
@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-10%, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInLeft {
    animation: fadeInLeft 1.25s;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(10%, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInRight {
    animation: fadeInRight 1.25s;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 10%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInUp {
    animation: fadeInUp 1.25s;
}